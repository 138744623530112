import React from "react";

const BodyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="138"
      viewBox="0 0 106 138"
      fill="none"
    >
      <rect width="106" height="138" rx="8" fill="#4E4645" />
      <path
        d="M33.58 119.905H40.165V121H31.675V120.43L35.29 115.72L31.75 110.995V110.425H40.12V111.52H33.61L36.745 115.72L33.58 119.905ZM49.7234 113.875L49.7684 112.765C51.8384 112.885 53.4734 114.415 53.4734 116.92C53.4734 119.47 52.1684 121.09 49.9484 121.09C48.7334 121.09 47.8634 120.31 47.5034 119.365C47.1284 120.31 46.2434 121.09 45.0584 121.09C42.8234 121.09 41.5184 119.47 41.5184 116.92C41.5184 114.415 43.1534 112.885 45.2234 112.765L45.2534 113.875C43.8284 114.07 42.7484 115.195 42.7484 116.92C42.7484 118.81 43.5884 119.98 45.1184 119.98C46.2134 119.98 46.9034 118.855 46.9034 117.805V115.975H48.0884V117.805C48.0884 118.855 48.7634 119.98 49.8734 119.98C51.3884 119.98 52.2434 118.81 52.2434 116.92C52.2434 115.195 51.1634 114.07 49.7234 113.875ZM50.5934 109.63L47.8784 112H46.9634L49.1984 109.51L50.5934 109.63ZM61.5845 112.885H62.8145V121H61.5845V120.19C60.9095 120.76 59.9495 121.09 58.8695 121.09C57.8645 121.09 56.9945 120.64 56.5295 119.815V123.565H55.2845V112.885H56.5295V117.43C56.5295 119.11 57.5345 119.98 58.9595 119.98C60.0545 119.98 60.9095 119.65 61.5845 119.065V112.885ZM71.6686 112.885H72.8986V121H71.6686V120.19C70.9186 120.745 69.9586 121.09 68.8486 121.09C66.5536 121.09 64.6786 119.305 64.6786 116.905C64.6786 114.49 66.5536 112.735 68.8486 112.735C69.9136 112.735 70.9336 113.08 71.6686 113.635V112.885ZM71.6686 119.035V114.82C71.0236 114.205 70.0936 113.845 69.0436 113.845C67.2736 113.845 65.9086 115.195 65.9086 116.905C65.9086 118.66 67.2736 119.98 69.0436 119.98C70.1086 119.98 71.0386 119.62 71.6686 119.035Z"
        fill="#F2EBE6"
      />
      <path
        d="M31.4601 0C31.0814 0 30.7741 0.349592 30.7741 0.78034C30.7741 8.21042 29.2881 15.5269 26.4753 21.9382C16.8493 43.8838 19.3647 71.2144 24.9289 89.4744C25.0277 89.7965 25.2933 90 25.5776 90C25.6522 90 25.7279 89.9863 25.8025 89.9563C26.1603 89.8152 26.3502 89.3707 26.2262 88.9637C20.7519 71.0034 18.2672 44.1472 27.7034 22.6348C30.6095 16.0088 32.146 8.45139 32.146 0.78034C32.146 0.349592 31.8387 0 31.4601 0Z"
        fill="#F2EBE6"
      />
      <path
        d="M77.6182 0.78034C77.6182 0.349592 77.3109 0 76.9323 0C76.5536 0 76.2463 0.349592 76.2463 0.78034C76.2463 8.45139 77.7828 16.0088 80.689 22.6348C90.1251 44.1472 87.6404 71.0022 82.1673 88.9625C82.0433 89.3695 82.2331 89.814 82.5909 89.955C82.6655 89.9838 82.7413 89.9987 82.8159 89.9987C83.1001 89.9987 83.3657 89.7965 83.4645 89.4731C89.0287 71.2132 91.5442 43.8825 81.917 21.9369C79.1042 15.5256 77.6182 8.20917 77.6182 0.779091V0.78034Z"
        fill="#F2EBE6"
      />
      <path
        d="M62.8987 44.4554L55.8561 52.7021C55.5631 53.0005 54.7268 53.2314 54.1967 53.2314C53.6666 53.2314 52.8303 53.0005 52.5373 52.7021L45.4947 44.4554C45.2314 44.147 44.7967 44.1395 44.5246 44.4392C44.2524 44.7388 44.2469 45.2333 44.5103 45.5429L51.464 53.6847C52.4715 57.2667 54.9221 66.5109 51.8481 89.0983C51.7899 89.5241 52.0467 89.9224 52.421 89.9886C52.4561 89.9948 52.4912 89.9973 52.5263 89.9973C52.8589 89.9973 53.1508 89.7214 53.2035 89.3368C53.6326 86.1867 53.9575 83.2976 54.1967 80.6357C54.436 83.2976 54.7608 86.1867 55.1899 89.3368C55.2426 89.7226 55.5345 89.9973 55.8671 89.9973C55.9022 89.9973 55.9373 89.9948 55.9724 89.9886C56.3467 89.9224 56.6035 89.5241 56.5453 89.0983C53.4713 66.5097 55.922 57.2667 56.9294 53.6847L63.8831 45.5429C64.1476 45.2333 64.141 44.7401 63.8688 44.4392C63.5978 44.1395 63.1632 44.1458 62.8987 44.4554ZM54.1967 60.2982C53.9136 58.102 53.5437 56.2504 53.1464 54.6423C53.5448 54.7434 53.9256 54.7909 54.1967 54.7909C54.4678 54.7909 54.8486 54.7434 55.247 54.6423C54.8497 56.2504 54.4799 58.102 54.1967 60.2982Z"
        fill="#F2EBE6"
      />
      <path
        d="M54.7454 11.1996C55.0824 11.3357 55.4259 11.4031 55.7584 11.4031C56.3423 11.4031 56.8965 11.1971 57.341 10.7976C57.6384 10.5304 57.6889 10.0397 57.454 9.70138C57.2192 9.36302 56.7879 9.30559 56.4904 9.57278C56.17 9.86119 55.6783 9.91987 55.2064 9.72884C54.7345 9.53782 54.3866 9.13704 54.2988 8.68381C54.2176 8.26305 53.8521 7.99587 53.4811 8.08951C53.1113 8.1819 52.8775 8.59891 52.9587 9.01967C53.1497 10.0085 53.8181 10.8226 54.7454 11.1996Z"
        fill="#F2EBE6"
      />
    </svg>
  );
};

export default BodyIcon;
