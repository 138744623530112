import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import Layout from "../components/common/Layout";
import "../styles/pages/case-studies.scss";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import Mastos from "../images/Mastos";
import Face from "../images/Face";
import BodyIcon from "../images/BodyIcon";
import CaseStudySlidingMenu from "../components/blocks/CaseStudySlidingMenu";
import Seo from "../components/meta/Seo";

const CaseStudies = ({ data }) => {
  const { caseStudy, settings, allCaseStudies } = data;

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);

  const [uniqueTags, setUniqueTags] = useState([]);

  useEffect(() => {
    const tags = allCaseStudies.nodes.map(serviceNode => serviceNode.tags[0]);
    const newUniqueTags = [...new Set(tags)];
    setUniqueTags(newUniqueTags);
  }, [allCaseStudies]);

  const [menuIndex, setMenuIndex] = useState(null);

  const openSlidingMenu = index => {
    setMenuIndex(index);
  };

  const closeSlidingMenu = () => {
    setMenuIndex(null);
  };

  useState(() => {
    allCaseStudies.nodes.find(caseStudyNode => {
      if (
        caseStudyNode.data.case_study_title.text ===
        caseStudy.data.case_study_title.text
      ) {
        setActiveAccordionKey(caseStudyNode.tags[0]);
      }
    });
  }, [caseStudy.data.case_study_title.text]);

  return (
    <div className="case-studies">
      <Seo page={caseStudy} />
      <Layout>
        <div className="max-width full-content">
          <div className="side-nav">
            <div className="case-studies-nav-mobile">
              <button
                title="open sliding menu 1"
                onClick={() => openSlidingMenu(1)}
              >
                <Face />
              </button>
              <button
                title="open sliding menu 2"
                onClick={() => openSlidingMenu(2)}
              >
                <Mastos />
              </button>
              <button
                title="open sliding menu 3"
                onClick={() => openSlidingMenu(3)}
              >
                <BodyIcon />
              </button>

              {menuIndex === 1 && (
                <CaseStudySlidingMenu
                  links={allCaseStudies.nodes}
                  tag="Πρόσωπο"
                  closeMenu={closeSlidingMenu}
                />
              )}
              {menuIndex === 2 && (
                <CaseStudySlidingMenu
                  links={allCaseStudies.nodes}
                  closeMenu={closeSlidingMenu}
                  tag="Μαστός"
                />
              )}
              {menuIndex === 3 && (
                <CaseStudySlidingMenu
                  links={allCaseStudies.nodes}
                  closeMenu={closeSlidingMenu}
                  tag="Σώμα"
                />
              )}
            </div>
            <Accordion
              className="case-studies-nav-desktop"
              activeKey={activeAccordionKey}
              onSelect={setActiveAccordionKey}
            >
              {uniqueTags.map(tag => (
                <Accordion.Item eventKey={tag} key={tag}>
                  <Accordion.Header className="no-border">
                    {tag}
                  </Accordion.Header>
                  <Accordion.Body>
                    {allCaseStudies.nodes
                      .filter(caseStudy => caseStudy.tags[0] === tag)
                      .map(caseStudy => (
                        <Link
                          activeClassName="active-link"
                          key={caseStudy.id}
                          to={caseStudy.url}
                        >
                          {caseStudy.data.case_study_title.text}
                        </Link>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="main-content">
            {caseStudy.data.body.map(item => {
              return item.slice_type === "before_and_after" ? (
                <div className="before-and-after" key={item.id}>
                  <div className="images">
                    <div className="before">
                      <GatsbyImage
                        image={item.primary.image_before.gatsbyImageData}
                      />
                      <span className="badge">ΠΡΙΝ</span>
                      {item.primary.image_before.alt && (
                        <span className="caption">
                          {item.primary.image_before.alt}
                        </span>
                      )}
                    </div>
                    <div className="after">
                      <GatsbyImage
                        image={item.primary.image_after.gatsbyImageData}
                      />
                      <span className="badge">ΜΕΤΑ</span>
                      {item.primary.image_after.alt && (
                        <span className="caption">
                          {item.primary.image_after.alt}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="before-after-content">
                    <PrismicRichText field={item.primary.content.richText} />
                  </div>
                </div>
              ) : (
                <div className="image-list">
                  <div className="image-list-content">
                    <PrismicRichText field={item.primary.content.richText} />
                  </div>
                  <div className="image-list-images">
                    {item.items.map(image => (
                      <div>
                        <GatsbyImage image={image.image.gatsbyImageData} />
                        <span className="caption">{image.image.alt}</span>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query caseStudiesQuerys($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    caseStudy: prismicCaseStudies(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        body {
          ... on PrismicCaseStudiesDataBodyBeforeAndAfter {
            id
            slice_label
            slice_type
            primary {
              content {
                richText
              }
              image_after {
                alt
                gatsbyImageData
              }
              image_before {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicCaseStudiesDataBodyPhotosList {
            id
            slice_label
            slice_type
            primary {
              content {
                richText
              }
            }
            items {
              image {
                gatsbyImageData
                alt
              }
            }
          }
        }
        case_study_title {
          text
        }
        main_image {
          gatsbyImageData
          alt
        }
      }
    }
    allCaseStudies: allPrismicCaseStudies {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
      nodes {
        data {
          case_study_title {
            text
          }
        }
        tags
        url
      }
    }
  }
`;

export default CaseStudies;
