import React from "react";
import { Link } from "gatsby";
import CloseIcon from "../../images/CloseIcon";

const CaseStudySlidingMenu = props => {
  return (
    <div className="case-study-sliding-menu">
      <button
        title="close-menu"
        className="close-case-study-menu-button"
        onClick={props.closeMenu}
      >
        <CloseIcon />
      </button>

      <div className="links-content">
        {props.links
          .filter(link => link.tags[0] === props.tag)
          .map(caseStudy => (
            <Link
              activeClassName="active-link"
              key={caseStudy.id}
              to={caseStudy.url}
            >
              {caseStudy.data.case_study_title.text}
            </Link>
          ))}
      </div>
      <div className="overlay"></div>
    </div>
  );
};

export default CaseStudySlidingMenu;
